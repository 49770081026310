<template>
  <div>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <div class="flex flex-column">
            <h3 class="blue-grey--text nunito lighten-3 mb-2 mt-2">
              <b>Status</b>
            </h3>
            <h5 class="nunito mb-2">
              Selected :
              {{ merchant_detail.offline }}
            </h5>
            <v-btn-toggle
              class="transparent"
              :value="merchant_detail.offline"
              @change="updateInput($event, 'offline')"
            >
              <v-btn :value="false">
                <span class="hidden-sm-and-down">Online</span>
              </v-btn>

              <v-btn :value="true">
                <span class="hidden-sm-and-down">Offline</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="flex flex-column">
            <h3 class="blue-grey--text nunito lighten-3 mb-2 mt-2">
              <b>List layout</b>
            </h3>
            <h5 class="nunito mb-2">
              Selected :
              {{
                merchant_detail.list_style == ""
                  ? "grid"
                  : merchant_detail.list_style
              }}
            </h5>
            <v-btn-toggle
              class="transparent"
              :value="merchant_detail.list_style"
              @input="updateInput($event, 'list_style')"
            >
              <v-btn value="">
                <span class="hidden-sm-and-down">Grid</span>
              </v-btn>

              <v-btn value="list">
                <span class="hidden-sm-and-down">List</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Identifier"
            dense
            color="info"
            outlined
            :value="merchant_detail.short_id"
            @input="updateInput($event, 'short_id')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="SST Tax (%)"
            dense
            outlined
            color="info"
            :value="merchant_detail.tax"
            @input="updateInput($event, 'tax')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Delivery fees"
            dense
            color="info"
            outlined
            :value="merchant_detail.delivery_fees"
            @input="updateInput($event, 'delivery_fees')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Delivery cap"
            dense
            outlined
            color="info"
            :value="merchant_detail.delivery_cap"
            @input="updateInput($event, 'delivery_cap')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Announcement"
            dense
            color="info"
            outlined
            :value="merchant_detail.announcement"
            @input="updateInput($event, 'announcement')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Checkout notes"
            dense
            color="info"
            outlined
            :value="merchant_detail.checkout_notes"
            @input="updateInput($event, 'checkout_notes')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense> </v-row>
      <h3 class="blue-grey--text nunito lighten-3 mb-2 mt-2">
        <b>Delivery</b>
      </h3>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Mrspeedy ID"
            color="info"
            dense
            outlined
            :value="merchant_detail.speedy_token"
            @input="updateInput($event, 'speedy_token')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Bungkusit API ID"
            color="info"
            dense
            outlined
            :value="merchant_detail.bungkusit_id"
            @input="updateInput($event, 'bungkusit_id')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Bungkusit Token Key"
            color="info"
            dense
            outlined
            :value="merchant_detail.bungkusit_key"
            @input="updateInput($event, 'bungkusit_key')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <div class="flex flex-column">
            <h3 class="blue-grey--text nunito lighten-3 mb-2">
              <b>Order methods</b>
            </h3>
            <h5 class="nunito mb-2">
              Selected :
              {{
                merchant_detail.order_methods == ""
                  ? "all"
                  : merchant_detail.order_methods
              }}
            </h5>
            <v-btn-toggle
              class="transparent"
              :value="merchant_detail.order_methods"
              @change="updateInput($event, 'order_methods')"
            >
              <v-btn :value="''">
                <span class="hidden-sm-and-down">All</span>
              </v-btn>

              <v-btn value="no_delivery">
                <span class="hidden-sm-and-down">No Delivery</span>
              </v-btn>
              <v-btn value="bo_pickup">
                <span class="hidden-sm-and-down">No Pickup</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="flex flex-column">
            <h3 class="blue-grey--text nunito lighten-3 mb-2">
              <b>Delivery methods</b>
            </h3>
            <h5 class="nunito mb-2">
              Selected :
              {{ merchant_detail.delivery_method }}
            </h5>
            <v-btn-toggle
              class="transparent"
              :value="merchant_detail.delivery_method"
              @change="updateInput($event, 'delivery_method')"
            >
              <v-btn value="own_delivery">
                <span class="hidden-sm-and-down">Own Delivery</span>
              </v-btn>

              <v-btn value="speedy">
                <span class="hidden-sm-and-down">MrSpeedy</span>
              </v-btn>
              <v-btn value="bungkusit">
                <span class="hidden-sm-and-down">Bungkusit</span>
              </v-btn>
              <v-btn value="uc">
                <span class="hidden-sm-and-down">User-Choice</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="flex justify-end mt-5">
      <v-btn color="success" @click="save">save</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  methods: {
    updateInput(e, index) {
      this.merchant_detail[index] = e;
      this.$store.commit("updateMerchantSettings", this.merchant_detail);
    },

    save() {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("saveMerchant");
    },
  },
};
</script>
